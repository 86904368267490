<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Contactos - Whatsapp Marketing</h4>
            <div class="small text-muted">Administración de contactos</div>
          </b-col>

          <b-col sm="5">
            <b-dropdown right text="Agregar" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Contacto">
              <b-dropdown-item @click="add()"><i class="fa fa-plus"></i> Agregar Contacto</b-dropdown-item>
              <b-dropdown-item @click="openImportCSV()"><i class="fa fa-file-excel-o"></i> Gestor de Importaciones</b-dropdown-item>
            </b-dropdown>            

            <b-col sm="12" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>  
        </b-row>
      </b-card>
      
      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="12">
            <b-row>
              <b-col md="3">
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" size="sm" placeholder="Ingrese un nombre o teléfono" v-model="filters.name" v-on:keyup.enter.native="load"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <v-select :options="arr.filters.list" v-model="filters.list_id" placeholder="Listas" :multiple="false" :select-on-tab="true"></v-select>
              </b-col>               
              <b-col md="3">
                <b-button variant="outline-dark" @click="load()" size="sm" class="mt-1">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
              <b-col md="3">
                <div class="pull-right" v-if="crud.formImport.arrErrors.length">
                  <b-link @click="openErrorImport()" title="Errores en Importación">
                    <b-icon icon="exclamation-square-fill" animation="fade" font-scale="2" variant="danger"></b-icon>
                  </b-link>
                </div>
              </b-col>                            
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"                
                    v-if="table.items.length">                       

                <template v-slot:cell(id)="data">
                  <b>{{data.item.id}}</b>
                </template> 

               <template v-slot:cell(name)="data">
                  <b>{{data.item.name}}</b>
                </template> 

                <template v-slot:cell(lists)="data">                  
                  <div v-if="data.item.lists">
                    <b-badge  variant="secondary"
                              class="mr-2 sellers-badge-zone"
                              v-for="value in data.item.lists"
                              :key="value.id">
                              {{value.name}}
                    </b-badge>
                  </div>
                </template>
               
                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
          <b-col>
            <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"
                                @input="load()" />
            </nav>
          </b-col>            
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"              
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-tabs card>
          <b-tab no-body title="General">                
            <b-row>
              <b-col md="12">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name"
                                required
                                placeholder="Ingresar un nombre">
                  </b-form-input>
                </b-form-group>                                              
              </b-col>     
              <b-col md="12">
                <b-form-group label="Número Whatsapp">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.phone"
                                required
                                placeholder="Cod país [sin el signo] + nueve [9] + cod área [sin el 0] + nro cel [sin el 15] (total 13 dígitos)">
                  </b-form-input>
                </b-form-group>                                              
              </b-col>
              <b-col md="12">
                <b-form-group label="Listas">
                  <b-form-select v-model="crud.form.lists" :options="arr.lists" multiple :select-size="10"></b-form-select>
                  <b-link href="javascript:void(0);" @click="unsuscribeLists()">Desuscribir de todas las Listas</b-link>
                </b-form-group>
              </b-col>
            </b-row>        
          </b-tab>
          <b-tab no-body title="Personalizado">      
            <b-row>
              <b-col md="12">
                <b-form-group label="Campo Personalizado 1">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.fieldCustom_1">
                  </b-form-input>
                </b-form-group>                                              
              </b-col>  
              <b-col md="12">
                <b-form-group label="Campo Personalizado 2">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.fieldCustom_2">
                  </b-form-input>
                </b-form-group>                                              
              </b-col>  
              <b-col md="12">
                <b-form-group label="Campo Personalizado 3">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.fieldCustom_3">
                  </b-form-input>
                </b-form-group>                                              
              </b-col>       
              <b-col md="12">
                <b-form-group label="Campo Personalizado 4">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.fieldCustom_4">
                  </b-form-input>
                </b-form-group>                                              
              </b-col>       
              <b-col md="12">
                <b-form-group label="Campo Personalizado 5">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.fieldCustom_5">
                  </b-form-input>
                </b-form-group>                                              
              </b-col>       
            </b-row>                                                                         
          </b-tab>
        </b-tabs>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal> 

      <!-- CRUD IMPORTACIONES -->
      <b-modal  v-model="modal.formImport.active"
                header-bg-variant="dark"
                header-text-variant="white"
                size="lg">
        <div slot="modal-header">
          {{this.modal.formImport.title}}
        </div>
        
        <b-tabs pills card vertical>
          <b-tab title="Contactos" @click="crud.formImport.type='import-contacts-whatsapp-marketing'" active >
            <b-card-text>
              <b-row>       
                <b-col md="12" class="mb-2">
                  <b-alert show variant="info">
                      <h4 class="alert-heading">Importación de Contactos</h4>
                      
                      <div>
                        <b-icon icon="arrow-right-short"/> El sistema importará todos los registros cargados en el archivo.
                      </div>
                      <div>
                        <b-icon icon="arrow-right-short"/> Esta importación permite agregar <b>Contactos</b> a las diferentes <b>Listas</b>.
                      </div>                      
                      <div>
                        <b-icon icon="arrow-right-short"/> Si el <b>contacto</b> está duplicado, no lo crea, lo asigna.
                      </div>
                      
                      <hr>

                      <div class="mb-0">
                        Descargue el <a href="javascript:void(0);" @click="goDownloadFormat('examples-imports/whatsapp-marketing/csv-format-contacts-lists.csv')"><b>formato de ejemplo</b></a> para esta importación.
                      </div>                                                                  
                    </b-alert>                  
                </b-col>
                <b-col md="12" class="mb-2">
                  <FindObject type="whatsappMarketingList" 
                              @select-object="loadListImportCSV($event)" 
                              :valueID="crud.formImport.list_id"
                              :where="conditionListActive"
                              render="search"/>                  
                </b-col>                
                <b-col md="12" class="mb-2">                  
                  <b-form-group label="Archivo CSV [Contactos]">                
                    <b-form-file v-model="crud.formImport.file"
                                  size="sm"
                                  :state="Boolean(crud.formImport.file)"
                                  placeholder="Elija un archivo o sueltelo aquí..."
                                  drop-placeholder="Suelta el archivo aquí...">
                    </b-form-file>                    
                  </b-form-group>         
                </b-col> 
              </b-row>              
            </b-card-text>
          </b-tab>
        </b-tabs>


        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formImport.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="importCSV()">Importar</b-button>          
        </div>

      </b-modal>

      <!-- ERRORES IMPORTACION -->
      <b-modal  v-model="modal.formErrorImport.active"
                header-bg-variant="dark"
                header-text-variant="white"
                size="lg">
        <div slot="modal-header">
          {{this.modal.formErrorImport.title}}
        </div>

        <div v-html="crud.formImport.arrErrors" class="whatsapp-marketing-error-import"></div>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formErrorImport.active=false">Cerrar</b-button>
        </div>        
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Imagen from '@/components/inc/image/image' 
  import FindObject from '@/components/inc/find/findObject'
  import moment from 'moment'
  
  export default {     
    components: {
      FindObject,
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.WHATSAPP_MARKETING,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudContact',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true},            
            {key: 'name', label: 'Nombre'},            
            {key: 'phone', label: 'Número Whatsapp'},   
            {key: 'lists', label: 'Listas'},
            {key: 'fieldCustom_1', label: 'Campo 1'},
            {key: 'fieldCustom_2', label: 'Campo 2'},
            {key: 'fieldCustom_3', label: 'Campo 3'},
            {key: 'fieldCustom_4', label: 'Campo 4'},
            {key: 'fieldCustom_5', label: 'Campo 5'},
            {key: 'f_action', label:''},
          ],
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          tablePaginate: null,    
        },
        crud: {
          form: {
            id: 0,
            name: '',
            phone: '',
            lists: [],
            fieldCustom_1: '',
            fieldCustom_2: '',
            fieldCustom_3: '',
            fieldCustom_4: '',
            fieldCustom_5: '',
          }, 
          formImport: {
            list: null,
            list_id: 0,            
            file: '',
            type: '',  
            arrErrors: [],
          }                   
        },
        arr : {
          lists: [],
          filters: {
            list: []
          }
        },
        filters: {          
          name: '', 
          list_id: 0,
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          formImport: {
            active: false,
            title: ''
          },          
          formErrorImport: {
            active: false,
            title: ''
          }                    
        },
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.load()
      this.filterLoadList()
    },
    computed: {
      // CONDITIONS SELECT
      conditionListActive(){
        return [{field: 'active', condition: true}];
      },          
    },
    methods: {
      // TABLA
      getRowCount (items) {
        return items.length
      },

      // CONTACTOS
      load() {
        let loader = this.$loading.show();
        var result = serviceAPI.filtrarWhatsappMarketingContact(this.filters, this.table.currentPage)

        result.then((response) => {
          var data = response.data          
          this.table.items = data.data 
          this.table.tablePaginate = data 

          loader.hide() 
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },            
      add() {
        this.crud.form.id = 0
        this.crud.form.name = ''
        this.crud.form.phone = ''
        this.crud.form.lists = []
        this.crud.form.fieldCustom_1 = ''
        this.crud.form.fieldCustom_2 = ''
        this.crud.form.fieldCustom_3 = ''
        this.crud.form.fieldCustom_4 = ''
        this.crud.form.fieldCustom_5 = ''

        this.cargarListas(this.crud.form.lists)

        this.modal.form.title = "Nuevo Contacto de Whatsapp"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id        
        this.crud.form.name = item.name
        this.crud.form.phone = item.phone     
        this.crud.form.fieldCustom_1 = item.fieldCustom_1
        this.crud.form.fieldCustom_2 = item.fieldCustom_2
        this.crud.form.fieldCustom_3 = item.fieldCustom_3
        this.crud.form.fieldCustom_4 = item.fieldCustom_4
        this.crud.form.fieldCustom_5 = item.fieldCustom_5

        this.crud.form.lists = []
        if(item.lists) [
          item.lists.forEach(element => {
            this.crud.form.lists.push(element.id)
          })
        ]
        
        this.cargarListas(item.lists)

        this.modal.form.title = "Editar Contacto de Whatsapp"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name
        this.crud.form.phone = item.phone

        this.$bvModal.msgBoxConfirm('Al eliminar un contacto se perderá el historial de envios realizado ¿Desea continuar de todas formas? Contacto (' + this.crud.form.id + ') ' + this.crud.form.name + ' - ' + this.crud.form.phone + '?', {
          title: 'Borrar Contacto',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarWhatsappMarketingContact(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        if(this.crud.form.phone.length < 11 || this.crud.form.phone.length > 13) {
          this.$awn.alert("El nro de télefono no posee un formato válido")
          return false
        }

        let loader = this.$loading.show();

        if (this.crud.form.id) {
          var result = serviceAPI.editarWhatsappMarketingContact(this.crud.form);
        } else {
          var result = serviceAPI.agregarWhatsappMarketingContact(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      cargarListas (lists) {
        var result = serviceAPI.obtenerWhatsappMarketingLists()        
        result.then((response) => {
          var data = response.data    

          this.arr.lists = []
          data.forEach(element => {            
              if(element.cant_contacts < 5500){
                this.arr.lists.push({ value: element.id, text: element.name })
              } else {
                if(lists.length > 0){
                  lists.forEach(value => {
                    if(element.id == value.id){
                      this.arr.lists.push({ value: value.id, text: value.name })
                    }
                  });
                }
              }
          });
        })   
      },
      
      // IMPORT BY CSV
      openImportCSV() {
        this.crud.formImport.type = 'import-contacts-whatsapp-marketing'
        this.crud.formImport.file = ''
        this.crud.formImport.list_id= 0
        this.crud.formImport.list= null
            
        this.modal.formImport.title = "Gestor de Importaciones"
        this.modal.formImport.active = true
      },
      importCSV() {     
        this.$bvModal.msgBoxConfirm('¿Continuar con la importación?', {
          title: 'Importar desde CSV',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'IMPORTAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {          
          if (value) {            
            let loader = this.$loading.show();

            var file = new FormData();
            file.append("file", this.crud.formImport.file);            
              
            var result = serviceAPI.importarContactsWhatsappMarketing(JSON.stringify(this.crud.formImport), file);

            result.then((response) => {              
              this.modal.formImport.active = false              
              loader.hide()
              this.load()
              if(response.data.length) {
                this.$awn.info("La importación finalizó. Hubo algunos contactos que no pudieron crearse");  
                this.crud.formImport.arrErrors = this.renderErrorImport(response.data)
              } else {
                this.$awn.success("Archivo importado con éxito");
                this.crud.formImport.arrErrors = []
              }                          
            })
            .catch(error => {    
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {         
          this.$awn.alert(Error.showError(error));
        })        
      },
      goDownloadFormat(value) {
        var win = window.open(Helper.getPathPublic(value), '_blank');        
        win.focus();
      },
      loadListImportCSV(object) {
        if(object){
          this.crud.formImport.list = object
          this.crud.formImport.list_id = object.id
        } else {
          this.crud.formImport.list = null
          this.crud.formImport.list_id = 0
        }
      },   
      renderErrorImport(items) {
        var text = ''
        if(items) {
          items.forEach(element => {
            text = text + element.name + ' - ' + element.phone + '<br>'
          });
        }
        return text
      },
      
      // ERROR IMPORT 
      openErrorImport() {
        this.modal.formErrorImport.title = "Errores en Importación"
        this.modal.formErrorImport.active = true
      },  
      
      // FILTER LIST
      filterLoadList() {
        var result = serviceAPI.obtenerWhatsappMarketingLists()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.list = []
          data.forEach(element => {                            
            this.arr.filters.list.push({ code: element.id, label: element.name })            
          });          
        })   
      },        
      
      // DESUSCRIBIR DE LISTAS
      unsuscribeLists() {
        this.crud.form.lists = [];
      }
    }    
  }
</script>
<style>
  .whatsapp-marketing-error-import {    
    max-height: 400px;
    overflow: auto;    
  }
</style>